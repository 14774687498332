//
// Nodes
//

// Admin

ul.tabs.clearfix {
    display: block;
    width: 90%;
    max-width: 1200px; 
    margin: auto;
    margin-top: $margin;
}

// HomePage
// 
// div.view-hp-banner-carousel img {
// 	-webkit-transform: scale(1);
// 	transform: scale(1);
// 	-webkit-transition: .3s ease-in-out;
// 	transition: .3s ease-in-out;
// }
// 
// div.view-hp-banner-carousel img:hover,
// div.view-hp-banner-carousel a:hover img {
// 	-webkit-transform: scale(1.3);
// 	transform: scale(1.3);
// }
// 
div.view-hp-banner-carousel a {
    color: $color_text_light !important;
}

div.owl-item:hover a,
div.owl-item a:hover {
    text-decoration: underline;
    color: $color_text_light !important;
}

div.owl-item .views-field-field-obrazek-hp {
    position: relative;
    z-index: 15;
}


div.owl-item .views-field-title  {
    position: absolute;
    top: 1em;
    width: 100%;
    margin-top: .45em;
    z-index: 50;
    div.h2ban {
        width: 90%;
        max-width: 1200px;
        margin: auto;
            h2 {
            width: 50%;
            font-size: $resp_font_h_bigg;
            font-range: $resp_range;
            text-align: left;
            color: $color_text_light;
            margin-top: .36em;
            display: block;
            
            }
        }
}


a.hpnews {
    display: block;
    font-family: $hfont;
    text-transform: uppercase;
    color: $color_text_light;            
    padding: $padding/2; 
    margin-top: $margin/2;
    margin-bottom: $margin/2;    
    background-color: $color_darkblue;
    color: $color_text_light;
    font-style: italic;
    float: left;
}

// Výrobky

body.page-node-1475 #block-system-main {
    margin: $margin 0;
    padding-top: $padding;
    padding-bottom: Oem !important;
    color: $color_darkblue;
    font-weight: 700;
    text-align: center;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        div.kategorie {
            display: block;
            @include gallery(9 of 18, of-type);
            margin-bottom: gutter();
            @include breakpoint($breakpoint_tablet) {
                @include gallery(18/4 of 18, of-type);
                margin-bottom: gutter();
            }
            @include breakpoint($breakpoint_desktop) {
                @include gallery(18/4 of 18, of-type);
                margin-bottom: gutter();
}
            a,
            a.hover {
                color: $color_text_light !important;
            }
            span {
                margin-top: -2.5em;
                display: block;
            }
            
        }
    }
}

// O nás

body.page-node-11 #region-content .block-inner.clearfix,
body.node-type--len-t-mu #region-content .block-inner.clearfix {
    width: 90%;
    max-width: 1200px; 
    margin: auto;
    padding-bottom: 2*$padding;
}

body.page-node-11 #page-title {
    display: none;
}


.onasbestof {
    display: block;
    @include gallery(9 of 18, of-type);
    margin-bottom: gutter();
    padding-right: $padding;
    h2 {
        display: block;
        min-height: 80px;
        margin-top: 0em;
    }
}

.clear_p {
    display: block;
    float: left;
    clear: both;
}

.icon {
    display: block;
    float: left;
    margin-right: $margin;
    max-width: 9%; 
}

#block-views-t-m-block-1 .views-row {
    @include gallery(18/3 of 18, of-type);
    margin-bottom: 2*gutter();
    padding-right: $padding;
    @include breakpoint($breakpoint_tablet) {
        @include gallery(18/4 of 18, of-type);
    }
    h2 {
        color: $color_darkblue;
        margin-top: .45em;
        margin-bottom: 0em;
    }
    
}

// Aktuality

body.page-aktuality #region-content .block-inner.clearfix,
body.node-type-article #region-content .block-inner.clearfix {
    width: 90%;
    max-width: 1200px; 
    margin: auto;
    padding-bottom: $padding;
}

body.page-aktuality {
    .views-row {
        padding-top: $margin;        
        margin-top: $margin;
        margin-bottom: $margin;
        clear: both;
        padding-bottom: $padding;
//        background-image: $ruler_dark;
//        background-repeat: no-repeat;
//        background-position: bottom left;
//        background-size: contain;
        @include breakpoint($breakpoint_tablet) {
            @include gallery(18/2 of 18, of-type);
        }
    }

    .views-field-field-uvodni-obrazek {
        max-width: 30%;
        margin-right: $margin;
        margin-bottom: $margin;
        height: auto;
        float: left;
        @include breakpoint($breakpoint_tablet) {
            max-width: 40%;
        }
    }
    h2 {
        margin-top: -0.1em;
        margin-bottom: 0em;
        color: $color_darkblue !important;
    }
    h2 a {
        color: $color_darkblue !important;
    }
    
}


body.node-type-article .field-name-field-uvodni-obrazek {
        max-width: 40%;
        margin-right: $margin;
        margin-bottom: $margin;
        height: auto;
        float: left;
        @include breakpoint($breakpoint_tablet) {
            max-width: 30%;
        }
}

// Reference

body.page-reference #region-content .block-inner.clearfix,
body.node-type-reference #region-content .block-inner.clearfix {
    width: 90%;
    max-width: 1200px; 
    margin: auto;
    padding-bottom: $padding;
}

body.page-reference {
    .views-row {
        padding-top: $margin;        
        margin-top: $margin;
        margin-bottom: $margin;
        clear: both;
        padding-bottom: $padding;
//        background-image: $ruler_dark;
//        background-repeat: no-repeat;
//        background-position: bottom left;
//        background-size: contain;
        @include breakpoint($breakpoint_tablet) {
            @include gallery(18/2 of 18, of-type);
        }
    }

    .views-field-field-uvodni-obrazek {
        max-width: 30%;
        margin-right: $margin;
        margin-bottom: $margin;
        height: auto;
        float: left;
        @include breakpoint($breakpoint_tablet) {
            max-width: 40%;
        }
    }
    h2 {
        margin-top: -0.1em;
        margin-bottom: 0em
    }
}


body.node-type-reference .field-name-field-uvodni-obrazek {
        max-width: 40%;
        margin-right: $margin;
        margin-bottom: $margin;
        height: auto;
        float: left;
        @include breakpoint($breakpoint_tablet) {
            max-width: 30%;
        }
}


// Sjednejte si schůzku 

body.page-node-4 #region-content .block-inner.clearfix {
    width: 90%;
    max-width: 1200px; 
    margin: auto;
    padding-bottom: 2*$padding;
}

body.page-node-4 .field-name-body {
    width: 100%;
    
//    padding-left: $padding;

    img {
        @include breakpoint($breakpoint_tablet) {

        }
    }
    @include breakpoint($breakpoint_tablet) {
        float: right;
        width: 50%;
        padding-left: $padding;
    }
        .obchodni_zastupce {
            float: left;
            width: 33%;
            padding-right: $padding;
            @include breakpoint($breakpoint_tablet) {
                margin-top: 1em;
            }
        }
}

#webform-client-form-4 {
    width: 100%;
    float: left;
    @include breakpoint($breakpoint_tablet) {
        max-width: 50%;
    }
}


#node-webform-4 #webform-component-vase-jmeno input,
#node-webform-4 #webform-component-vas-telefon input,
#node-webform-4 #webform-component-vas-e-mail input,
#node-webform-4 #webform-component-firma input,
#node-webform-4 #webform-component-s-cim-vam-muzeme-pomoci textarea,
#node-webform-4 .grippie {
    border: none;
    background: #eaeaea;
    width: 100%;
}

#edit-submit {
    border: none;
    background: $project_color;
    color: $color_text_light;
    font-family: $hfont;
    font-style: italic;
    text-shadow: none;
}

// Kontakt


.sidlofirmy {
    width: 100%;
    @include breakpoint($breakpoint_tablet) {
        width: 40%;
        float: right;
        padding-left: 2*$padding;
    }
    img {
        width: 100%;
        height: auto;
    }
    p {
        margin-top: 0em;
        padding-top: 0em;
    }
}

body.page-node-30 table {
    width: 100%;
    @include breakpoint($breakpoint_tablet) {
        width: 60%;
        float: left;
    }
}

body.page-node-30 .content {
    padding-top: $padding;
}

// Přehled objednávky

.basic-cart-cart-total-price-contents.row {
    display: none;
}

.cell.basic-cart-cart-unit-price {
    display: none;
}

#edit-buttons-update,
#edit-buttons-checkout {
    display: block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    color: #eff0f1 !important;
    padding: .45em;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_darkblue;
    color: #eff0f1;
    font-style: italic;
    border: none;
    background-image: none;
    text-shadow: none;
    float: left;
}

#continue-shoping {
    display: block;
    float: left;
    margin-right: .9em;
    display: block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    color: #eff0f1 !important;
    padding: .25em;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_darkblue;
    font-style: italic;
    border: none;
    background-image: none;
    text-shadow: none;
    float: left;
    font-weight: bold;
}

#edit-buttons-update {
    background-color: $project_color;
    margin-right: $margin;
}

#edit-buttons-checkout {
    background-color: $color_a;
}

#order-node-form #edit-title,
#order-node-form #edit-field-firma-und-0-value,
#order-node-form #edit-field-email-und-0-value,
#order-node-form #edit-field-phone-und-0-value,
#order-node-form #edit-field-address-und-0-value,
#order-node-form #edit-field-city-und-0-value,
#order-node-form #edit-field-zipcode-und-0-value,
#order-node-form #edit-body-und-0-value,
#order-node-form .grippie {
    border: none;
    background: #eaeaea;
    width: 100%;
}

#order-node-form .form-item-field-souhlas-und label {
	float: left;	
	margin-right: $margin;
}

#order-node-form #edit-field-souhlas-und-nesouhlas {
    display: none;
}



// Výrobek NODE

body.node-type-produkt {
	#zone-content,
	#section-content {
	   background: $color_lightgreen;
	}

	div.galleryformatter {
	ul {
	   padding-left: 0em !important;
        }
	ul li::before {
	   display: none;
        }
    }
    .field-name-field-popis-produktu p {
       line-height: 1em;       
    }
    .field-name-field-popis-produktu p::before {
       content: '› ';
    }
    .field-name-add-to-cart {
    width: 100%;
        
        
       @include breakpoint($breakpoint_tablet) {
           float: left;
           width: 62%;
       }
       .field-item.odd {
    	   display: none;
	   }
    }
    .field-name-field-pdf-dal-soubory .field-item {
        float: left;
    }
    .field-name-field-pdf-dal-soubory span.file {
        display: inline-block;
        font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
        text-transform: uppercase;
        font-style: italic;
        margin-right: $margin;
        img.file-icon {
            display: none;
        }
        a,
        a:hover {
            color: $color_darkblue !important;
            padding: $padding/2 $padding;
            margin-top: .27em;
            margin-bottom: .27em;
            background-color: $color_white;
            border: solid 1px $color_darkblue;
            display: block;
            min-width: 10em;
        }
    }
}
body.node-type-produkt .basic-cart-add-to-cart-link {
    display: inline-block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    color: #eff0f1;
    padding: $padding/2 $padding;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_darkblue;
    color: #eff0f1;
    font-style: italic;
//    float: right;
    margin-right: $margin;
}


.galleryformatter {
//    float: left;
    margin: 0 .9em .9em 0;
}

.galleryformatter .gallery-slides, .galleryformatter .gallery-thumbs {
	margin: 0em;
}


body.node-type-produkt #page-title {
    margin-bottom: $margin;
}

body.node-type-produkt .field-name-field-fotografie {
        margin-right: $margin;
        margin-bottom: $margin;
        width: 100%;
        @include breakpoint($breakpoint_tablet) {
            float: left;
            width: 36%;
        }
        .field-slideshow {
            margin: auto;
        }
        .field-slideshow-controls {
            margin-top: 1em;
        }
        a.prev {
            text-align: right;
            margin-left: 38% !important;
            margin-right: 2% !important;
        }
        a.next,
        a.prev {    
            padding: $padding;
            padding: 0px 15px !important;
            font-size: 1.6em !important;
            color: #FFF;
            display: inline-block;
            zoom: 1;
            *display: inline;
            margin: 5px;
            padding: 3px 10px;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background: #869791;
            filter: Alpha(Opacity=50);
            opacity: 0.5;
            width: auto;
        }
        a.prev:hover,
        a.next:hover {
            filter: Alpha(Opacity=100);
            opacity: 1;
            text-decoration: none;
    }
}   

body.node-type-produkt .field.field-name-field-katalogove-oznaceni {
        margin-bottom: .9em;
        @include breakpoint($breakpoint_tablet) {
            float: left;
        margin-left: 1%;
    }
}


body.node-type-produkt .field.field-name-field-popis-produktu {
    @include breakpoint($breakpoint_tablet) {
        float: right;
        width: 62%;
    }
}

body.node-type-produkt .field.field-name-field-pdf-dal-soubory  {
    @include breakpoint($breakpoint_tablet) {
        float: left;
    }
}








