//
// Blocks
//

#block-views-hp-banner-carousel-block {
    
    @include breakpoint($breakpoint_tablet) {
        padding-top: 9.45em;
    }
    h2 {
//        text-shadow: 1px 1px 0px #CCCCCC;
    }
}

.owl-theme .owl-controls .owl-buttons div {
    padding: 0px 15px !important;
    font-size: 1.6em !important;
}
    
/*** Počty zákazníků ***/

#block-block-3 {
    margin: 5*$margin 0;
    background-color: $color_darkblue;

    .block-inner.clearfix {
        background-color: $color_darkblue;
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding: $padding/2;
        img {
                max-width: 96% !important;
                margin: 2%;
                float: left;            
            }
        @include breakpoint($breakpoint_tablet) {
            img {
                max-width: 48% !important;
                margin: 1%;
                float: left;            
            }
        }
    }
}

/*** Kategorie ***/
#block-block-9 {
    margin: 5*$margin 0;
    padding-top: $padding;
    padding-bottom: Oem !important;
    color: $color_darkblue;
    font-weight: 700;
    text-align: center;
    h2 {
        font-size: $resp_font_h1;
        font-range: $resp_range;
    }
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
    }
}

div.kategorie {
    display: block;
    @include gallery(9 of 18, of-type);
    margin-bottom: gutter();
    @include breakpoint($breakpoint_tablet) {
        @include gallery(18/4 of 18, of-type);
        margin-bottom: gutter();
    }
    @include breakpoint($breakpoint_desktop) {
        @include gallery(18/4 of 18, of-type);
        margin-bottom: gutter();
    }
    a,
    a.hover {
        color: $color_text_light !important;
    }
    span {
        margin-top: -2.5em;
        display: block;
    }
    h2 {
        font-size: $resp_font_h1;
        font-range: $resp_range; 
    }
}

#block-block-14, /* Půda */
#block-block-15, /* Voda */
#block-block-16, /* Meteo */
#block-block-17, /* Geo */
#block-block-18, /* Sedi */
#block-block-19, /* Rostl */
#block-block-20, /* Odpad */
#block-block-21  /* Ostant */ {
    padding: .45em 0;
//    text-align: center;
        .block-inner.clearfix {
        width: 90%;
        max-width: 1200px;
        margin: auto;
            div.kategorie {
               display: block;
               @include gallery(9 of 18, of-type);
               margin-bottom: gutter();
               @include breakpoint($breakpoint_tablet) {
                   @include gallery(18/4 of 18, of-type);
                   margin-bottom: gutter();
               }
               @include breakpoint($breakpoint_desktop) {
                   @include gallery(18/4 of 18, of-type);
                   margin-bottom: gutter();
}   
                a,
                a.hover {
                    color: $color_text_light !important;
                }
                span {
                    margin-top: -2.5em;
                    display: block;
                }
            }
        }
}

/*** Hlavní text ***/

body.front #block-system-main {
    margin: 5*$margin 0;
    background-color: $color_lightblue;
    padding-top: $padding;
    padding-bottom: Oem !important;
    text-align: left;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        background-image: $ruler;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    }
}

/*** Aktuality ***/

#block-views-aktuality-block-1 {
    margin: 5*$margin 0;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
    }
    .views-row {
        padding-right: $padding;
        padding-top: 3*$padding;
        clear: both;
        img {
            width: 30%;
            height: auto;
            float: left;
            padding-right: $padding;
            padding-bottom: $padding;
        }
        h2 {
            margin-top: 0em;
            margin-bottom: 0em;
            color: $color_darkblue;
        }
        h2 a {
            color: $color_darkblue !important;
        }
        p {
            margin-bottom: 0em;
        }
        @include breakpoint($breakpoint_tablet) {
        @include gallery(18/2 of 18, of-type);
            img {
            width: 40%;
            height: auto;
            }
        }
    }
}

/*** Unikátní výhody***/

#block-block-8 {
    margin: 5*$margin 0;
    background-color: $project_color;
    padding-top: $padding;
    padding-bottom: 0em !important;
    color: $color_darkblue;
    font-weight: 700;
    text-align: center;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        background-image: $ruler;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
        @include breakpoint($breakpoint_tablet) {
            padding-bottom: 4*$padding;
        }
    }
    div.uniq {
        height: 10em;
        text-align: center;
        @include gallery(18/4 of 18, of-type);
        
        @include breakpoint($breakpoint_tablet) {
        @include gallery(18/4 of 18, of-type);
            height: 12em;
        }
        p {
            padding-top: 4em;
            @include breakpoint($breakpoint_tablet) {
                padding-top: 6em;
            }
        }
    }
    .ruce {
        background-image: $ruce;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 42%;
        @include breakpoint($breakpoint_tablet) {
            
        }
    }
    .tel {
        background-image: $tel;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 25%;
    }
    .lupa {
        background-image: $lupa;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 25%;
    }
    .strom {
        background-image: $strom;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 25%;
    }
    
}


/*** Loga partnerů ***/

#block-views-hp-logo-carousel-block {
    margin: 5*$margin 0;
    text-align: center;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
    }
    .owl-theme .owl-controls .owl-buttons div {
    background: $project_color;
    filter: Alpha(Opacity=100);
    opacity: 1;
    }
    
}

/*** Přijedeme ***/

#block-block-6 {
    margin: 5*$margin 0;
    padding-top: $padding;
    padding-bottom: Oem !important;
    color: $color_darkblue;
    font-weight: 700;
    text-align: center;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        background-image: $vyzva;
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        padding: $padding*3 0em;
    }
    .white.bigg {
        padding-bottom: 1em;
        width: 90%;
        max-width: 1200px;
        margin: auto;
        font-size: $resp_font_h_bigg;
        font-range: $resp_range;
    }
    .call_fa_button {
        bottom: 5.4em;
        width: 90%;
        max-width: 1200px;
        margin: auto;
        display: block;
        
    }
    .call_fa_button_a {
        font-family: $hfont;
        text-transform: uppercase;
        color: $color_text_light;            
        padding: $padding; 
        background-color: $project_color;
        font-style: italic;
    }
}

/*** Newsletter ***/

#block-block-7 {
    margin: 5*$margin 0;
    background-color: $project_color;
    padding-top: $padding;
    padding-bottom: 0em !important;
    color: $color_darkblue;
    font-weight: 700;
    text-align: center;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        background-image: $ruler;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    }
    input {
        text-align: center;
    }
    label {
        display: inline-block;   
    }
    
}

// O nás - Ekotechnika, proč se vyplatí spolůupráce

#block-block-11 {
    padding-top: $padding;
    padding-bottom: 0em !important;
    background-color: $project_color;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        background-image: $ruler;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    }
}

// Mimořádná garance

#block-block-12 {
    padding-top: $padding;
    padding-bottom: 0em !important;
    background-color: $color_lightblue;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
        background-image: $ruler;
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: contain;
    }
}

// Mapa

.responsivemap {
    overflow:hidden;
    padding-bottom: 30%; //56.25%;
    position:relative;
    height:0;
}
.responsivemap iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

// Kategorie - menu

#block-superfish-2 {
    position: relative;
    z-index: 30;
    .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
        padding-bottom: 2*$padding;
    }
}