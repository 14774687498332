//
// Footer
//

#section-footer {
    color: $color_text_light;
//	margin-top: $padding*5;
  img {
    height: auto;
  }
    h1, h2, h3 {
        color: $color_text_light;
    }
    #webform-component-mam-zajem-o label {
        color: $color_text_light;
    }
}

.page-node-30 #section-footer {
	margin-top: 0em;
}
    
#region-footer-first {
    width: 100%;
    float: left;
    background-color: $background_color_footer_first;
    margin: 0;
  padding-top: $padding;
    @include breakpoint($breakpoint_tablet) {
        padding: 2*$padding 0;

  }
}

.region-inner.region-footer-first-inner {
    margin: auto;
    width: 90%;
    max-width: 1200px;
}

#block-block-2,
#block-block-5 { 
    padding-top: $padding;
}

#block-block-2,
#block-block-5 {
    @include breakpoint($breakpoint_tablet) {
    @include gallery(9 of 18, of-type);
    }
    @include breakpoint($breakpoint_desktop) {
    @include gallery(9 of 18, of-type);
    }
}

#block-block-2 {
    img {
        max-width: 90% !important;
    }
    @include breakpoint($breakpoint_tablet) {
    }    
    .footer_kontakt {
        @include gallery(9 of 18, of-type);
    }
    .footer_menu {
        margin-top: .72em;
        padding-left: 7*$padding;
        padding-right: $padding;
        border-left: solid 1px $color_text_light;
        @include gallery(9 of 18, of-type);
        @include breakpoint($breakpoint_tablet) {
            border-right: solid 1px $color_text_light;        
        }
        a,
        a:hover {
            color: $project_color;
        }
    }
}
#block-block-5 {
    padding-top: $padding;
    margin-top: $margin;
    margin-bottom: $margin;
    border-top: solid 1px $color_text_light;
    @include breakpoint($breakpoint_tablet) {
        padding-top: .72em;
        margin-top: 0em;
        margin-bottom: 0em;
        padding-left: 3*$padding;
        border-top: none;
    }    
}

a.footer_button {
    display: block;
    font-family: $hfont;
    text-transform: uppercase;
    color: $color_text_light;            
    padding: $padding/2; 
    margin-top: $margin/2;
    margin-bottom: $margin/2;    
    background-color: $project_color;
    color: $color_text_light;
    font-style: italic;
    float: left;
}

#region-footer-second {
    width: 100%;
    float: left;
    background-color: $background_color_footer;
    text-align: center;
    padding: $padding 0;
    margin: 0;
    @include breakpoint($breakpoint_tablet) {
        padding: 2*$padding 0;
        text-align: right;
    }
}

.region-inner.region-footer-second-inner {
    margin: auto;
    width: 90%;
    max-width: 1    440px;
}


span.tel::before {
    font-family: $ifontsolid;
    font-size: $base_fontsize;
    font-size: $resp_font;
    font-range: $resp_range;
    letter-spacing: $resp_spacing;
    letter-spacing-range: $resp_range;
    content: '\f098';
    margin-right: 0.18em;
}

span.mail::before {
    font-family: $ifontsolid;
    font-size: $base_fontsize;
    font-size: $resp_font;
    font-range: $resp_range;
    letter-spacing: $resp_spacing;
    letter-spacing-range: $resp_range;
    content: '\f199';
    margin-right: 0.18em;
}
