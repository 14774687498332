//
// Body
//

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {

  @include breakpoint($breakpoint_tablet) {

  }
  @include breakpoint($breakpoint_desktop) {
      div.region-inner {
          // padding-left: $padding;
          // padding-right: $padding;
          }
  }
}

#block-system-main .block-inner.clearfix {
        width: 90%;
        max-width: 1200px; 
        margin: auto;
}

#sliding-popup.sliding-popup-top {
  clear: both;
  float: left;
}

#section-content {
    @include breakpoint($breakpoint_desktop) {
//        padding-top: 4.5em;
    }
}

#zone-content {
    background-color: $region_color;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
        padding-top: 1em;   
    	margin-bottom: $padding*5;
    @include breakpoint($breakpoint_desktop) {
//        padding-top: 5em;
    }
}

#region-content {
    margin: 0;
}

body.not-front #section-content {
    @include breakpoint($breakpoint_tablet) {
        padding-top: 10em;
    }    
}


body.page-node-11 #section-content {
    @include breakpoint($breakpoint_tablet) {

    }    
}

.region-inner.region-content-inner {
}