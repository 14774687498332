//
// Header
//

#section-header {
//    margin-bottom: $margin*3;
//    background-color: $region_color;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
    
    @include breakpoint($breakpoint_tablet) {
        width: 100%;
        display: block;
        z-index: 30;
    }
}

#zone-branding {
    height: 5.5em;
    @include breakpoint($breakpoint_tablet) {
        height: 9em;
    }
}

#region-branding {
//    padding-top: 0.5em;
}

#zone-branding-wrapper {
    background-color: $background_color_header;
    border-bottom: solid 4px $project_color; 
    
    @include breakpoint($breakpoint_tablet) {
        position: fixed;
        z-index: 50;
        width: 100%;
    }
}


.site-name {
    margin-top: 0em;
    padding-bottom: em(16px);
    padding-top: em(16px);
    margin-bottom: em(6px);
        @include breakpoint($breakpoint_tablet) {
            margin-top: 0.9em;
            margin-bottom: em(0px);
            padding-top: em(0px);
    }
}

.site-name-slogan {
    width: 50%;
    @include breakpoint($breakpoint_tablet) {
        width: 25%;
    }
}

h1.site-name a,
h2.site-name a
{
  text-decoration: none;
}


h1.site-name,
h2.site-name {
    font-size: 16px !important;
    width: 108px !important;
  a {
    color: $color_text_dark;
    text-decoration: none !important;
    // border-bottom: solid 3px $project_color;
  }
    a:hover {
        color: $project_color;
    }    
  
}

h6.site-slogan {
    padding-left: .45em;
    font-weight: bold !important;
  font-family: $font;
    font-size: $resp_font_h6;
    font-range: $resp_range;
    margin-top: .3em;
    @include breakpoint($breakpoint_tablet) {
        margin-top: .63em
    }
}

.branding-data {
    float: left;
    max-width: 50%;
    padding-bottom: .5em;
    padding-top: .5em;
    @include breakpoint($breakpoint_tablet) {
        max-width: 60%;
        margin-right: .5em;
        padding-top: .81em;
    }
    @include breakpoint($breakpoint_desktop) {
    max-width: 80%;
    margin-right: .5em;
    }
}

.region-inner.region-branding-inner {
    width: 90%;
    max-width: 1200px; 
    margin: auto;
}

.logo-img {
    margin-right: $margin/2;
    margin-top: .3em;
    width: 40%;
}


#block-search-form {
    width: 10em;
    display: block;
    float: right;
    margin-top: .27em;
    padding-top: .9em;
    padding-bottom: .5em;
    
    #edit-search-block-form--2 {
        border: none;
        background: #eaeaea;
        
    }
    #edit-submit {
        display: block;
        border: none;
        height: 1.8em;
    }
    .form-item.form-type-textfield.form-item-search-block-form {
        float: left;
        margin: 0em;
    }
}

#block-views-exp-search-api-results-page {
    width: 15em;
    display: block;
    float: right;
    margin-top: .27em;
    padding-top: .9em;
    padding-bottom: .5em;
    
    #edit-search-api-views-fulltext {
        border: none;
        background: #eaeaea;
        
    }
    #edit-submit {
        display: block;
        border: none;
        height: 1.8em;
    }
    .form-item.form-type-textfield.form-item-search-block-form {
        float: left;
        margin: 0em;
    }
    .views-exposed-form .views-exposed-widget {
        float: none;
        margin: 0;
        padding: 0;
    }
    #edit-search-api-views-fulltext-wrapper {
        float: left;
    }   
}


#region-menu {
    margin: 0;
}