//
// Views
//

// Všechny výrobky

.view-id-v_robky {

    h2.field-content {
        clear: both;
    }


}

// Kategorie výrobku



body.page-taxonomy-term #region-header-first {
    @include breakpoint($breakpoint_tablet) {
        margin-left: 0em;    
    } 
}

body.page-taxonomy-term {
    .views-row {
        background: $color_lightgreen;
        padding: $padding;
        margin-bottom: $margin*3;
        min-height: 21em;
        float: left;
        width: 100%;
    }
    .views-field-field-fotografie {
        margin-right: $margin;
        margin-bottom: $margin;
        width: 100%;
        @include breakpoint($breakpoint_tablet) {
            float: left;
            width: 36%;
        }
        .field-slideshow {
            margin: auto;
        }
        .field-slideshow-controls {
            margin-top: 1em;
        }
        a.prev {
            text-align: right;
            margin-left: 38% !important;
            margin-right: 2% !important;
        }
        a.next,
        a.prev {    
            padding: $padding;
            padding: 0px 15px !important;
            font-size: 1.6em !important;
            color: #FFF;
            display: inline-block;
            zoom: 1;
            *display: inline;
            margin: 5px;
            padding: 3px 10px;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            background: #869791;
            filter: Alpha(Opacity=50);
            opacity: 0.5;
            width: auto;
        }
        a.prev:hover,
        a.next:hover {
            filter: Alpha(Opacity=100);
            opacity: 1;
            text-decoration: none;
        }
    }
    .views-field.views-field-title {
        @include breakpoint($breakpoint_tablet) {
        width: 63%;
        margin-left: 37%;
        text-align: left;
            h2 {
                margin-top: 0em !important;
            }
        }
    }
    .views-field-field-katalogove-oznaceni {
        font-weight: 600;
        margin-bottom: $margin;
        @include breakpoint($breakpoint_tablet) {
            width: 63%;
            margin-left: 37%;
            text-align: left;
        }
    }
    .views-field-field-popis-produktu  {
        @include breakpoint($breakpoint_tablet) {
            width: 63%;
            margin-left: 37%;
            text-align: left;
        }
    }
    
    .views-field-field-popis-produktu p {
        line-height: 1em;
    }
    .views-field-field-popis-produktu p::before {
        content: '› ';
    }
}

div.views-field-field-technick-specifikace,
div.views-field-field-intern-informace,
div.views-field-field-pdf-dal-soubory,
div.views-field-add-to-cart {
    @include breakpoint($breakpoint_tablet) {
//        float: left;
        margin-left: 37%;
    }
}

body.page-taxonomy-term .basic-cart-add-to-cart-link {
    display: inline-block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    color: #eff0f1;
    padding: $padding/2 $padding;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_darkblue;
    color: #eff0f1;
    font-style: italic;
//    float: right;
    margin-right: $margin;
    min-width: 10em;
}

span.views-label-field-technick-specifikace:hover {
    cursor: pointer;
}

span.views-label-field-technick-specifikace {
    display: inline-block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    padding: $padding/2 $padding;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_lightblue;
    font-style: italic;
//    float: right;
    margin-right: $margin;
    min-width: 10em;
}

span.views-label-field-intern-informace:hover {
    cursor: pointer;
}

span.views-label-field-intern-informace {
    display: inline-block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    padding: $padding/2 $padding;
    margin-top: .45em;
    margin-bottom: .45em;
    background-color: $color_lightblue;
    font-style: italic;
//    float: right;
    margin-right: $margin;
    min-width: 10em;
}


.views-field-field-pdf-dal-soubory span.file {
    display: inline-block;
    font-family: "MyriadProBold","pt_sansbold","open-sans",arial;
    text-transform: uppercase;
    font-style: italic;
    margin-right: $margin;
    img.file-icon {
        display: none;
    }
    a,
    a:hover {
        color: $color_darkblue !important;
        padding: $padding/2 $padding;
        margin-top: .27em;
        margin-bottom: .27em;
        background-color: $color_white;
        border: solid 1px $color_darkblue;
        display: block;
        min-width: 10em;
    }
}



body.page-taxonomy-term div.views-field-field-technick-specifikace div.field-content {
    display: none
}

body.page-taxonomy-term div.views-field-field-intern-informace div.field-content {
    display: none
}

body.page-taxonomy-term #section-content {
    padding-top: 2em;    
    @include breakpoint($breakpoint_tablet) {
        padding-top: 10em;    
    } 
}

body.page-taxonomy-term #block-system-main {
    padding: $padding 0;
}

// Search

p.search-info {
    display: none;
}