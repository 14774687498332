//
// Files
//

$bg_pattern: url(../images/BackgroundPattern.png);
$ruler: url(../images/PravitkoLight.png);
$ruler_dark: url(../images/PravitkoDark.png);
$ruce: url(../images/Hands.png);
$lupa: url(../images/Lupa.png);
$strom: url(../images/Tree.png);
$tel: url(../images/Tel.png);
$vyzva: url(../images/VyzvaKakci.jpg);
