//
// Colors
//

$background_color_body: rgba(#ffffff, 1);
$background_color_footer_first: rgba(#252525, 1);
$background_color_footer: rgba(#000000, 1);
$background_color_header: rgba(#ffffff, 1);
$color_background_black: rgba(#000000, 1);
$bg_contact: rgba(#efefef, 1);;
$color_text: rgba(#333333, 1);
$color_text_light: rgba(#eff0f1, 1);
$color_text_dark: rgba(#333333, 1);
$dark_grey: rgba(#666666, 1);
$light_grey: rgba(#cccccc, 1);
$color_h: rgba(#006600, 1);
$color_a: rgba(#cb2b0e, 1);
$color_border: rgba(#86bc56, 1);
$color_menu_border: rgba(#e5e5e5, 1);
$color_lightblue: rgba(#d2dde8, 1);
$color_darkblue: rgba(#20538a, 1);
$color_lightgreen: rgba(#f1f7eb, 1); 
$project_color: rgba(#86bc56, 1);
$region_color: rgba(#ffffff, 1);
$region_blue_color: rgba(#d2dde8, 1);
$reserved_color: rgba(#cccccc, 1);
$dark_reserved_color: rgba(#666666, 1);
$color_white: rgba(#ffffff, 1);
$color_black: rgba(#000000, 1);

.project_color {
    color: $project_color;
}

.reserved_color {
    color: $reserved_color;
}

span.reserved_color {
    color: $dark_reserved_color;
}