//
// Forms
//

[type="checkbox"], [type="radio"] {
    display: inline;
    margin-right: .375em;
    margin-top: 1px;
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}