//
// Typo
//

@font-face {
  font-family: 'MyriadPro';
  src: url('../fonts/MyriadPro/MyriadPro-Regular.eot');
  src: url('../fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
       url('../fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype'),
       url('../fonts/MyriadPro/MyriadPro-Regular.svg#MyriadPro') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProBold';
  src: url('../fonts/MyriadProBold/MyriadPro-Bodl.eot');
  src: url('../fonts/MyriadProBold/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/MyriadProBold/MyriadPro-Bold.woff') format('woff'),
       url('../fonts/MyriadProBold/MyriadPro-Bold.ttf') format('truetype'),
       url('../fonts/MyriadProBold/MyriadPro-Bold.svg#MyriadProBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_sansregular';
  src: url('../fonts/PtSans/PTS55F-webfont.eot');
  src: url('../fonts/PtSans/PTS55F-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PtSans/PTS55F-webfont.woff') format('woff'),
       url('../fonts/PtSans/PTS55F-webfont.ttf') format('truetype'),
       url('../fonts/PtSans/PTS55F-webfont.svg#pt_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pt_sansbold';
  src: url('../fonts/PtSans/PTS75F-webfont.eot');
  src: url('../fonts/PtSans/PTS75F-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PtSans/PTS75F-webfont.woff') format('woff'),
       url('../fonts/PtSans/PTS75F-webfont.ttf') format('truetype'),
       url('../fonts/PtSans/PTS75F-webfont.svg#pt_sansbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Regular.eot');
  src: url('../fonts/RobotoSlab/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/RobotoSlab/RobotoSlab-Regular.woff') format('woff'),
       url('../fonts/RobotoSlab/RobotoSlab-Regular.ttf') format('truetype'),
       url('../fonts/RobotoSlab/RobotoSlab-Regular.svg#robotoslab') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesomeSolid';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/FontAwesome/fa-solid-900.eot');
    src: url('../fonts/FontAwesome/fa-solid-900.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FontAwesome/fa-solid-900.woff') format('woff'),
         url('../fonts/FontAwesome/fa-solid-900.woff2') format('woff2'),
         url('../fonts/FontAwesome/fa-solid-900.ttf') format('truetype'),
         url('../fonts/FontAwesome/fa-solid-900.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'FontAwesomeRegular';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/FontAwesome/fa-regular-400.eot');
    src: url('../fonts/FontAwesome/fa-regular-400.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FontAwesome/fa-regular-400.woff') format('woff'),
         url('../fonts/FontAwesome/fa-regular-400.woff2') format('woff2'),
         url('../fonts/FontAwesome/fa-regular-400.ttf') format('truetype'),
         url('../fonts/FontAwesome/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'FontAwesomeBrands';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/FontAwesomxe/fa-brands-400.eot');
    src: url('../fonts/FontAwesome/fa-brands-400.eot?#iefix') format('embedded-opentype'),
         url('../fonts/FontAwesome/fa-brands-400.woff') format('woff'),
         url('../fonts/FontAwesome/fa-brands-400.woff2') format('woff2'),
         url('../fonts/FontAwesome/fa-brands-400.ttf') format('truetype'),
         url('../fonts/FontAwesome/fa-brands-400.svg#fontawesome') format('svg');
}

$base_fontsize: 16px;

$font: 'MyriadPro', 'pt_sansregular', 'open-sans', arial;
$hfont: 'MyriadProBold', 'pt_sansbold', 'open-sans', arial;
$ifont: 'FontAwesomeRegular';
$ifontsolid: 'FontAwesomeSolid';

$resp_range: 26.25em 90em;

$resp_font: responsive 0.81em 1em;
$resp_small_font: responsive 0.63em 0.81em;
$resp_font_h1: responsive 1.3em 1.8em;
$resp_font_h2: responsive 1.15em 1.3em;
$resp_font_h3: responsive 1em 1.1em;
$resp_font_h6: responsive .81em 1.3em;
$resp_font_h_bigg: responsive 1.1em 2.5em;
$resp_menu_font: responsive 1em 1.08em;

// font-size: $resp_small_font;
// font-range: $resp_range;

$resp_ifont: responsive 0.75em 1.125em;
$resp_spacing: responsive 0.054em 0.09em;
// letter-spacing: $resp_spacing;
// letter-spacing-range: $resp_range;

$resp_lineheight: responsive 1.2em 1.8em ;
// line-height: $resp_lineheight;
// line-height-range: $resp_range;





strong {
    font-weight: bold;
}

/*
$base_fontsize_tablet: 1.5vw;
$base_fontsize_desktop: 0.9vw;
$base_lineheight: 1.6;
$fontsize_small: 2.7vw;
$fontsize_small_tablet: 1vw;
$fontsize_small_desktop: 0.63vw;

$fontsize_h1: 9vw;
$lineheight_h1: 1.25;
$fontsize_h1_tablet: 5.4vw;
$lineheight_h1_tablet: 1.25;
$fontsize_h1_desktop: 3.6vw;
$lineheight_h1_desktop: 1.25;

$fontsize_h2: 6vw;
$lineheight_h2: 1.5;
$fontsize_h2_tablet: 2.8vw;
$lineheight_h2_tablet: 1.35;
$fontsize_h2_desktop: 2.3vw;
$lineheight_h2_desktop: 1.25;

$fontsize_menu_main: 4vw;
$lineheight_menu_main: 1;
$fontsize_menu_main_tablet: 1.6vw;
$lineheight_menu_main_tablet: 1;
$fontsize_menu_main_desktop: 1.1vw;
$lineheight_menu_main_desktop: 1;

$fontsize_submenu_main: 4vw;
$fontsize_submenu_main_tablet: 1.5vw;
$fontsize_submenu_main_desktop: 1vw;
*/