//
// Aktuality
//

.page-aktuality {
    .views-field.views-field-field-image {
        float: left;
        padding: 0 2*$padding 2*$padding 0;
    }
//    .views-row {
//    border-bottom: solid 1px $color_border;
//    padding: 2*$padding 0;
//    min-height: 180px;
//    }
    .views-row-last {
    border-bottom: none;
    }
    
}