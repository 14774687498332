//
// Menu
//

#block-superfish-1 {
    float: left;
    width: 100%;
    padding-bottom: 3px;
    @include breakpoint($breakpoint_tablet) {
        border-top: solid 2px $color_darkblue;
    }
}

#block-superfish-1 a,
#block-superfish-1 span {
    font-size: $base_fontsize;
    font-size: $resp_menu_font;
    font-range: $resp_range;
    color: $color_darkblue;
    font-weight: 600;
        @include breakpoint($breakpoint_tablet) {

    }
}

#menu-368-1 a.active,
#menu-368-1 a:hover {
    text-decoration: none;
}


#menu-368-1 a {
    padding: 0.75em 1em;
    display: inline-block;
    text-indent: -9999px;
    width: 3em;
}


#menu-368-1 a::before {
    font-family: $ifontsolid;
    font-size: $base_fontsize;
    font-size: $resp_menu_font;
    font-range: $resp_range;
    content: '\f015';
    text-indent: 0px;
    float: left;
    display: inline-block;
    color: $color_text_dark;
}

#block-superfish-1 {

    @include breakpoint($breakpoint_tablet) {
        margin-top: 0.3em;
    }
}

.sf-menu.sf-style-space {
    padding-top: .9em;
    padding-bottom: .5em;
    margin-bottom: 0;
}

.mean-container .mean-nav ul li span {
    display: block;
    float: left;
    width: 90%;
    padding: 1em 5%;
    margin: 0;
    text-align: left;
    color: $color_text_light;
    border-top: 1px solid #383838;
    border-top: 1px solid rgba(255,255,255,.5);
    text-decoration: none;
    text-transform: uppercase;
}

.mean-container .mean-nav ul li a.mean-expand {
    width: auto !important;
    height: auto !important;
    font-size: inherit !important;
}

.sf-menu.sf-style-space li,
.sf-menu.sf-style-space a,
.sf-menu.sf-style-space span.nolink {
//    background: $project_color;
    background: transparent;
    text-decoration: none;
    border: none;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
}

.sf-menu.sf-style-space a,
.sf-menu.sf-style-space span.nolink {
    border-right: 2px solid $project_color;
    padding: 0.3em 0.9em;

}

.sf-menu.sf-style-space #menu-1825-1 a {
    border-right: none !important;
}

.sf-menu.sf-style-space li.last a {
    border-right: none !important;
//    margin-left: 1em;
//    margin-right: 1em;
    color: $project_color !important;
}

.sf-menu.sf-style-space li:hover, 
.sf-menu.sf-style-space li.sfHover, 
.sf-menu.sf-style-space a:focus,
.sf-menu.sf-style-space a:hover, 
.sf-menu.sf-style-space a:active,
.sf-menu.sf-style-space a.active,
.sf-menu.sf-style-space span.nolink:hover, 
.sf-menu.sf-style-space.sf-navbar li li {
//    background: $project_color;
    color: $project_color !important;
    background: transparent;
    text-decoration: underline !important;
    
    
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
}

// Superfish menu - product categories


#zone-header-wrapper {
    width: 90%;
    max-width: 1200px;
    margin: 6.3em auto 0em auto;
    z-index: 30;
    position: relative;
}


body.page-taxonomy-term #block-superfish-1 ol,
body.page-taxonomy-term #block-superfish-1 ul {
  padding-left: 0em;
}

#superfish-2 {
  padding-left: 0em !important;
}

.sf-accordion-toggle {
    padding: $padding*2 0em;
}

div.sf-accordion-toggle.sf-style-default a,
.sf-accordion-toggle.sf-style-default a,
.sf-accordion-toggle.sf-style-default a:visited,
.sf-accordion-toggle.sf-style-default span.nolink,
.sf-menu.sf-style-default a, 
.sf-menu.sf-style-default a:visited, 
.sf-menu.sf-style-default span.nolink {
    color: #ffffff;
    font-weight: 600;
    text-decoration: none !important;
}

div.sf-accordion-toggle.sf-style-default a,
.sf-accordion-toggle.sf-style-default li,
.sf-accordion-toggle.sf-style-default li li,
.sf-accordion-toggle.sf-style-default li li li,
.sf-accordion-toggle.sf-style-default.sf-navbar,
.sf-accordion-toggle.sf-style-default a,
.sf-accordion-toggle.sf-style-default span.nolink,
.sf-menu.sf-style-default li,
.sf-menu.sf-style-default li li,
.sf-menu.sf-style-default.sf-navbar,
.sf-menu.sf-style-default a,
.sf-menu.sf-style-default span.nolink,
div.sf-accordion-toggle.sf-style-default a.sf-expanded, 
.sf-menu.sf-style-default.sf-accordion li.sf-expanded {
    background: $project_color;
    color: #ffffff;
}

div.sf-accordion-toggle.sf-style-default a:hover,
div.sf-accordion-toggle.sf-style-default a.active,
div.sf-accordion-toggle.sf-style-default a.sf-expanded:hover, 
div.sf-accordion-toggle.sf-style-default a.sf-expanded.active:hover, 
.sf-accordion-toggle.sf-style-default a:hover,
.sf-accordion-toggle.sf-style-default a.active,
.sf-accordion-toggle.sf-style-default span.nolink:hover,
.sf-accordion-toggle.sf-style-default li:hover,
.sf-accordion-toggle.sf-style-default li li:hover,
.sf-accordion-toggle.sf-style-default li li li:hover,
.sf-accordion-toggle.sf-style-default li.active-trail,
.sf-accordion-toggle.sf-style-default li li.active-trail,
.sf-accordion-toggle.sf-style-default li li li.active-trail,
.sf-accordion-toggle.sf-style-default.sf-navbar:hover
.sf-menu.sf-style-default li:hover, 
.sf-menu.sf-style-default li li:hover, 
.sf-menu.sf-style-default li li li:hover, 
.sf-menu.sf-style-default.sf-navbar:hover {
    background: $color_black;
    text-decoration: none !important;
}

.sf-accordion-toggle.sf-style-default a,
.sf-accordion-toggle.sf-style-default span.nolink,
.sf-menu.sf-style-default a,
.sf-menu.sf-style-default span.nolink {
//    padding: 0.3em 0.72em;
}

.sf-accordion-toggle.sf-style-default li:hover, 
.sf-accordion-toggle.sf-style-default li.sfHover,
.sf-accordion-toggle.sf-style-default a:focus,
.sf-accordion-toggle.sf-style-default a:hover, 
.sf-accordion-toggle.sf-style-default a:active,
.sf-accordion-toggle.sf-style-default a.active,
.sf-accordion-toggle.sf-style-default span.nolink:hover, 
.sf-accordion-toggle.sf-style-default .sf-navbar li li,
.sf-menu.sf-style-default li:hover, 
.sf-menu.sf-style-default li.sfHover, 
.sf-menu.sf-style-default li.active-trail,
.sf-menu.sf-style-default li.active-trail > a,
.sf-menu.sf-style-default a:focus,
.sf-menu.sf-style-default a:hover, 
.sf-menu.sf-style-default a:active,
.sf-menu.sf-style-default a.active,
.sf-menu.sf-style-default span.nolink:hover, 
.sf-menu.sf-style-default.sf-navbar li li,
.sf-menu.sf-style-default.sf-navbar li li li,
.sf-menu.sf-style-default.sf-navbar li.active-trail,
.sf-menu.sf-style-default.sf-navbar li li.active-trail,
.sf-menu.sf-style-default.sf-navbar li li li.active-trail {
    background: $color_darkblue;
    color: #ffffff;
    text-decoration: none !important;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
}

.sf-menu.sf-style-default a, 
.sf-menu.sf-style-default a:visited, 
.sf-menu.sf-style-default span.nolink {
    padding: 0.75em;
}

.sf-sub-indicator {
    right: .81em !important;
    top: .81em !important;
}

.sf-menu.sf-style-default a.sf-with-ul, 
.sf-menu.sf-style-default span.nolink.sf-with-ul {
    padding-right: 2.7em;
}