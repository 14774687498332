//
// HTML Elements
//

html,
body {
    font-family: $font !important;
    color: $color_text;
    font-size: $base_fontsize;
    font-size: $resp_font;
    font-range: $resp_range;
    background-color: $background_color_body;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
}

p, dl, hr, h1, h2, h3, h4, h5, h6, ol, ul, pre, table, address, fieldset {
    margin-bottom: $margin/2;
}

a,
a:hover,
a.active,
a:link,
a:visited {
  text-decoration: none;
  color: $color_a;
}

a.active,
a:hover,
input.form-submit:hover {
  text-decoration: underline;
}


p, pre {
    padding: $padding/2 0;
    line-height: $resp_lineheight;
    line-height-range: $resp_range;
    font-size: $resp_font;
    font-range: $resp_range;
}

p.smallfont {
    font-size: $resp_small_font;
    font-range: $resp_range;
}

p.smallgreyfont {
    font-size: $resp_small_font;
    font-range: $resp_range;
    color: $dark_grey;
} 

.redfont {
    color: #cb2b0e;
}

img {
  max-width: 100% !important;
  height: auto !important;

}

div.owl-carousel img {
  width: 100%;
} 


h1, h2, h3, h4, h5, h6, h2.block-title {
    font-family: $hfont;
    margin-bottom: $margin/2;
    margin-top: $margin;
//    text-transform: uppercase;
    color: $project_color;
    line-height: $resp_lineheight;
    line-height-range: $resp_range;    
    letter-spacing: 0em;
    font-style: italic;
    font-weight: 900;
}

h3, h4, h5, h6 {
    color: $color_text; 
}

h1 {
    font-size: $resp_font_h1;
    font-range: $resp_range;
        @include breakpoint($breakpoint_tablet) {
        }
        @include breakpoint($breakpoint_desktop) {
        }
}

h1.blue {
    font-size: $resp_font_h1;
    font-range: $resp_range;
    color: $color_darkblue;
}


h2.blue {
    font-size: $resp_font_h2;
    font-range: $resp_range;
    color: $color_darkblue;
}

h1.white,
h2.white {
    font-size: $resp_font_h1;
    font-range: $resp_range;
    color: $color_white;
    margin-bottom: $margin;
}

#page-title {
    width: 90%;
    max-width: 1200px;
    margin: auto;
//    margin-bottom: $margin;
//    background-color: $dark_grey;
//    background-image:  $bg_pattern;
//    background-repeat: repeat;
//    background-position: 0 0;
    color: $color_darkblue;
  @include breakpoint($breakpoint_tablet) {
  }
  @include breakpoint($breakpoint_desktop) {
  }
}
  
h2,
h2.block-title {
    font-size: $resp_font_h2;
    font-range: $resp_range;
        @include breakpoint($breakpoint_tablet) {
        }
        @include breakpoint($breakpoint_desktop) {
        }
}

h2 a,
h2 a:hover,
h3 a,
h3:hover {
    color: $project_color !important;
}

#zone-branding h3 {
    color: $project_color !important;
    margin-top: 0;
}

body.front #page-title {
    display: none;

}

body.front h3.hpbene {
    font-size: $resp_font_h3;
    font-range: $resp_range;
    color: $project_color !important;
    margin-bottom: 0.1em;
}

#section-content ol,
#section-content ul
{
  padding-left: 1.8em;
}




#section-content ul li
{
  list-style: none;
}

#block-system-main ul li:before
{
 /* set color to red */
  content:"\2022"; /* /2022 is the numerical unicode for &bull; */
  font-size:1.5em; /* set the font-size of the bullet if necessary. Make sure to use em or %*/
  padding-right:.25em; /* give the bullet some padding from the text Make sure to use em*/
  position:relative;
  top:.1em; /* set the vertical position of the bullet if necessary. Make sure to use em or % */
}

ul.pager,
ul.pager li {
    border: none;
    border-radius: 0em !important;
    -moz-border-radius: 0em !important;
    -webkit-border-radius: 0em !important;
    background: #eaeaea;
}

ul.pager li a {
    background: #eaeaea;
}

ul.pager li:before {
    display: none;
    background: #eaeaea;
}


ul.pager li.pager-first,
ul.pager li.pager-first a,
ul.pager li.pager-previous,
ul.pager li.pager-previous a,
ul.pager li.pager-next,
ul.pager li.pager-next a,
ul.pager li.pager-last,
ul.pager li.pager-last a {
    width: 7em;
}

.pager li.pager-next {
    right: 7em;
}

td,
th {
    padding: .18em;
}


table, 
thead th,
th, 
td {
    border: 1px solid $color_text_dark;
    border-right: none;
    border-left: none;
}

thead th, 
th {
    background: $light_grey;
}

tr:hover td, 
tr.even:hover td.active, 
tr.odd:hover td.active {
    background-color: transparent;
}

.page-node-19 tr:hover td, 
.page-node-19 tr.even:hover td.active, 
.page-node-19 tr.odd:hover td.active {
    background-color: $project_color;
    background-image:  $bg_pattern;
    background-repeat: repeat;
    background-position: 0 0;
    color: $color_text_light;
}

tr.prodany:hover,
.page-node-19 tr.prodany:hover td, 
.page-node-19 tr.prodany.even:hover td.active, 
.page-node-19 tr.prodany.odd:hover td.active {
    background: $color_a;
    color: $color_text;
}

tr.reserved:hover,
.page-node-19 tr.reserved:hover td, 
.page-node-19 tr.reserved.even:hover td.active, 
.page-node-19 tr.reserved.odd:hover td.active {
    background: $reserved_color;
    color: $color_text;
}

button,
input,
select,
textarea,
input[type="reset"],
input[type="submit"],
input[type="button"] {
    border: 1px solid #b3b3b0;
    border-radius: 0px 0px 0px 0px !important;
    max-width: 100%;
    font-size: $base_fontsize;
    font-size: $resp_font;
    font-range: $resp_range;
    letter-spacing: $resp_spacing;
    letter-spacing-range: $resp_range;
}

button:focus,
button:active,
input:focus,
input:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  box-shadow: 0 0 7px #696969;
}

textarea, 
select, 
input[type="date"], 
input[type="datetime"], 
input[type="datetime-local"], 
input[type="email"], 
input[type="month"], 
input[type="number"], 
input[type="password"], 
input[type="search"], 
input[type="tel"], 
input[type="text"], 
input[type="time"], 
input[type="url"], 
input[type="week"],
label {
    font-family: $font;
    color: $color_text;
    font-size: $base_fontsize;
    font-size: $resp_font;
    font-range: $resp_range;
    letter-spacing: $resp_spacing;
    letter-spacing-range: $resp_range;
}

sup {
	top: 0.3em;
}

sub {
	bottom: 0.3em;
}